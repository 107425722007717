import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Button, Container, Stack } from "react-bootstrap";
import { FaArrowCircleUp } from "react-icons/fa";
import { useEffect } from "react";

import { motion } from "framer-motion";

import useCategories from "./hooks/useCategories";
import useScroll from "./hooks/useScroll";

import Home from "./views/pages/Home";
import Category from "./views/pages/Category";
import Loading from "./views/pages/Loading";
import Navbar from "./components/navigation/Navbar";
import Footer from "./components/navigation/Footer";
import ErrorPage from "./views/pages/ErrorPage";
import News from "./views/pages/News";
import Legal from "./components/legalText/Legal";
import ModalWrapper from "./wrappers/ModalWrapper";
import ModalCookies from "./components/shared/ModalCookies";

function App() {
  const { categories, isLoading: isLoadingCategory, error } = useCategories();
  const { visible, scrollToTop } = useScroll();

  if (isLoadingCategory) return <Loading />;
  if (error) return <ErrorPage />;

  return (
    <BrowserRouter>
      <ModalWrapper>
        <i
          style={{
            fontSize: 40,
            bottom: "4vh",
            right: "1vw",
            cursor: "pointer",
            zIndex: 100,
          }}
          className="fas fa-angle-up px-4 position-fixed"
          onClick={() => window.scrollTo(0, 0)}
        />
        <ScrollToTop />
        <ModalCookies />
        <Stack className="min-vh-100">
          <Navbar categories={categories} />
          {visible && (
            <motion.div>
              <Button className="position-fixed button-scroll" variant="light">
                <FaArrowCircleUp onClick={scrollToTop} />
              </Button>
            </motion.div>
          )}
          <Container fluid>
            <Router />
          </Container>
          <Footer categories={categories} />
        </Stack>
      </ModalWrapper>
    </BrowserRouter>
  );
}

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="category/:identifier" element={<Category />}></Route>
      <Route
        path="category/:identifier/news/:id/:title"
        element={<News />}
      ></Route>
      <Route path="news/:id/:title" element={<News />}></Route>
      <Route path="cookies" element={<Legal />}></Route>
    </Routes>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

export default App;
