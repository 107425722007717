import { Col, Container, Row } from "react-bootstrap";

import Banner from "./Banner";

import classNames from "classnames";

const BannerGrid = ({ banners, className }) => {
    return (
        <Container  className={classNames("mt-4", className)}>
            <Row className="justify-content-center grid-area">
                {banners.map((banner, i) =>
                    <Col xs={i === 0 ? 12 : 3} key={i} style={{ gridArea: i === 0 ? "first-banner" : `others-banner-` + i }} className={i === 0 ? "first-banner-news" : "others-banner-news"}>
                        <Banner isPrincipalBanner={i === 0 ? true : false} {...banner} />
                    </Col>
                )}
            </Row>
        </Container>
    );
}

export const BannerCategory = ({ banners }) => {
    return (
        <>
            {banners.length > 0 &&
                <div  className="mt-5 mx-auto">
                    <Row className="mx-auto container separator m-0 d-flex justify-content-center">
                        {banners.map((banner, i) =>
                            <Col key={i} md={4} xs={6} className="banners">
                                <Banner  {...banner} readMore={true}  allWidth={true}/>
                            </Col>
                        )}
                    </Row>
                </div>
            }
        </>
    )
}

export default BannerGrid;
