import { useState } from "react";

const useScroll = () =>{
    const [visible, setVisible] = useState(false) 
  
    const toggleVisible = () => { 
      const scrolled = document.documentElement.scrollTop; 
      if (scrolled > 100){ 
        setVisible(true) 
      }  
      else if (scrolled <= 100){ 
        setVisible(false) 
      } 
    }; 
    
    const scrollToTop = () =>{ 
      window.scrollTo({ 
        top: 0,  
        behavior: 'smooth'
      }); 
    }; 
    
    window.addEventListener('scroll', toggleVisible); 
    return{ visible,scrollToTop}
}

export default useScroll;