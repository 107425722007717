
import { Col, Container, Row } from "react-bootstrap";

import NavLink from "../../shared/NavLink";
import classNames from "classnames";

const CategoryBanner = ({ image_url, title, authors, description, time_published, url, className, similarNews }) => {

    return (
        <Container className={classNames("mt-4 p-0 h-auto mb-3", className)}>
            <Row className="flex-row h-auto">
                <Col md={5} className={classNames("order-2 h-100", similarNews ? "order-md-2" : "order-md-1 my-auto py-2")}>
                    <Row className={classNames("h-100 flex-column g-2", similarNews ? "justify-content-between" : "justify-content-center")}>
                        <Col className="fw-bold p-2 title"><NavLink to={url}><h4>{title}</h4></NavLink></Col>
                        <Col>{description}</Col>
                        <Col><span className="text-small text-muted">{authors || time_published ? `By ${authors} ,${time_published}` : ""}</span></Col>
                    </Row>
                </Col>
                <Col className={classNames("order-1 image-container", similarNews ? "order-md-1":"order-md-2")}><img src={image_url} style={{ objectFit: 'cover' }} alt="banner" /></Col>
            </Row>
        </Container>
    )
}

export default CategoryBanner;