
import { Container } from "react-bootstrap";
import ReactLoading from "react-loading";

const Loading = () => {
    return (
        <Container fluid className="w-100  d-flex justify-content-center align-items-center  min-vh-100">
            <ReactLoading type="bubbles" color="#790e7c"
                height={200} width={80} />
        </Container>
    )
}

export default Loading;