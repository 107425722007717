import { useParams } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";

import useNews from "../../hooks/useNews";
import useCategories from "../../hooks/useCategories";

import CategoryGrid from "../../components/banner/category/CategoryGrid";
import { BannerCategory } from "../../components/banner/BannerGrid";
import ErrorPage from "./ErrorPage";
import Loading from "./Loading";


const Category = () => {
    const { identifier } = useParams();
    const [currentTotalBanners, setCurrentTotalBanners] = useState(6);
    
    useEffect(() => { setCurrentTotalBanners(6)}, [identifier]);

    const { categories, isLoading, error: categoryError } = useCategories({
        filters: { "filters[identifier][$eq]": identifier },
        depsToReset: [identifier]
    });
    const { news, error, isLoading: newsIsLoading } = useNews({
        filters: { "filters[category][identifier][$eq]": identifier },
        depsToReset: [identifier]
    });

    const firstNews = useMemo(() => news && news[0],// eslint-disable-next-line 
    [identifier, news]);
    const allBanners = useMemo(() => news.filter((n) => firstNews !== n).slice(0, currentTotalBanners),// eslint-disable-next-line
     [identifier, news, currentTotalBanners]);
    const totalNews = useMemo(() => news.filter((n) => firstNews !== n).length,// eslint-disable-next-line
     [news, identifier]);

    const handlLoad = () =>  setCurrentTotalBanners((prev) => prev += (window.innerWidth <= 768 ? 8 : 6));

    const somethingIsLoading = isLoading || newsIsLoading;
    if (somethingIsLoading) return <Loading />;
    if (error || categoryError) return <ErrorPage />;

    return (
        <Container fluid className="mt-lg-2 mb-5 category-page">
            <h1 className="category-page-title container fw-bold">{categories && categories[0].name}</h1>
            <hr className="container pb-2" />
            <CategoryGrid  {...firstNews} />
            <BannerCategory banners={allBanners} />
            {allBanners.length < totalNews &&
                <Container className="my-5 m-auto text-center">
                    <Button variant="secondary" className="w-25 w-sm-50 text-dark fw-bold" onClick={handlLoad}> Load more</Button>
                </Container>
            }
        </Container>
    )
}

export default Category;