import { Container } from "react-bootstrap";

const Legal = () => (
  <Container className="legalText" style={{overflow: 'hidden'}}> 

    <h1 className="text-primary fw-bold">COOKIE POLICY</h1>
    <p>
      This Cookie Policy is an integral part of the General Terms and Conditions
      and the Privacy Policy. Accessing and browsing the site, or using its
      services, implies acceptance of the General Terms and Conditions (and
      therefore the Privacy Policy and Cookie Policy). Please read them
      carefully.
    </p>
    <p>
      Thank you for visiting us. We want your experience on the site to be as
      good as possible, which is why we have written this Cookie Policy as
      transparently as possible.
    </p>
    <h2>1. Types of Cookies</h2>
    <p>
      There are different types of cookies. They all work in the same way, but
      we offer a description of each one with their particularities:
    </p>
    <ol type="a">
      <li>
        According to the <b>entity that manages them</b>, there are first-party
        cookies (those that are sent to the user's terminal from a computer or
        domain managed by the editor itself and from which the service requested
        by the user is provided) and third-party cookies (those that are sent to
        the user's terminal from a computer or domain that is not managed by the
        editor, but by another entity that processes the data obtained through
        the cookies).
      </li>
      <li>
        According to the <b>time they remain active</b>, there are{" "}
        <b>session cookies</b> (designed to collect and store data while the
        user accesses a web page) and <b>persistent cookies</b> (in which the
        data is stored in the terminal and can be accessed and processed for a
        period defined by the cookie's controller - it can range from a few
        minutes to several years).
      </li>
      <li>
        According to the purpose for which they process the information they
        collect, they can be:
        <ul>
          <li>
            <u>Technical cookies</u>: necessary for the use of the web and the
            provision of the contracted service.
          </li>
          <li>
            <u>Personalization cookies</u>: allow the user to access the service
            with predefined characteristics, such as language, browser type,
            regional configuration, etc.
          </li>
          <li>
            <u>Analysis cookies</u>: collect information on the use of the web.
          </li>
          <li>
            <u>Advertising cookies</u>: collect information about users'
            preferences and personal choices.
          </li>
          <li>
            <u>Affiliate cookies</u>: allow us to track visits from other
            websites with which the website establishes an affiliation contract.
          </li>
        </ul>
      </li>
    </ol>
    <p>
      While browsing our website, cookies from our social networks may be stored
      in your browser. Below are the links to the cookie policies of the social
      networks we use on this website:
    </p>
    <ul>
      <li>
        Facebook cookies, for more information see their{" "}
        <a
          href="https://es-es.facebook.com/policies/cookies/"
          rel="noreferrer"
          target="_blank"
        >
          cookie policy
        </a>
        .
      </li>
      <li>
        Twitter cookies, for more information see their{" "}
        <a
          href="https://help.twitter.com/es/rules-and-policies/twitter-cookies"
          rel="noreferrer"
          target="_blank"
        >
          cookie policy
        </a>
        .
      </li>
      <li>
        Instagram cookies, for more information see their{" "}
        <a
          href="https://es-es.facebook.com/policies/cookies/"
          rel="noreferrer"
          target="_blank"
        >
          cookie policy
        </a>
        .
      </li>
      <li>
        Google+ cookies, for more information see their{" "}
        <a
          href="https://policies.google.com/technologies/cookies?hl=es"
          rel="noreferrer"
          target="_blank"
        >
          cookie policy
        </a>
        .
      </li>
      <li>
        LinkedIn cookies, for more information see their{" "}
        <a
          href="https://www.linkedin.com/legal/cookie-policy?_l=es_ES"
          rel="noreferrer"
          target="_blank"
        >
          cookie policy
        </a>
        .
      </li>
      <li>
        YouTube cookies, for more information see their{" "}
        <a
          href="https://es-es.facebook.com/policies/cookies/"
          rel="noreferrer"
          target="_blank"
        >
          cookie policy
        </a>
        .
      </li>
    </ul>
    <p>
      <b>User Settings to Avoid Cookies</b>
    </p>
    <p>
      At FITNESS BYTES S.L., in compliance with current legal regulations, we
      provide you with the information that allows you to configure your
      Internet browser to maintain your privacy and security regarding cookies.
    </p>
    <p>
      We offer information and links to the official support sites of the main
      browsers so that you can decide whether or not to accept the use of
      cookies.
    </p>
    <p>
      You have the option to block cookies through your browser's configuration
      tools so that it notifies you when a server wants to save a cookie:
    </p>
    <ol type="a">
      <li>
        If you use MICROSOFT INTERNET EXPLORER, go to Tools {">"} Internet
        Options {">"} Privacy {">"} Settings.
      </li>
      <p>
        For more information, visit:{" "}
        <a
          href="http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookiesyhttp://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9"
          rel="noreferrer"
          target="_blank"
        >
          http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookiesyhttp://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9
        </a>
      </p>
      <li>
        If you use FIREFOX, go to Tools {">"} Options {">"} Privacy {">"}{" "}
        Cookies.
      </li>
      <p>
        For more information, visit:{" "}
        <a
          href="http://support.mozilla.org/es/products/firefox/privacy-and-security"
          rel="noreferrer"
          target="_blank"
        >
          http://support.mozilla.org/es/products/firefox/privacy-and-security
        </a>
      </p>
      <li>
        If you use Chrome, go to Options {">"} Advanced Options {">"} Privacy.
      </li>
      <p>
        For more information, visit:{" "}
        <a
          href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647"
          rel="noreferrer"
          target="_blank"
        >
          http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647
        </a>
      </p>
      <li>
        If you use Opera, go to Security and Privacy options to configure the
        browser.
      </li>
      <p>
        For more information, visit:{" "}
        <a
          href="http://www.opera.com/help/tutorials/security/cookies/"
          rel="noreferrer"
          target="_blank"
        >
          http://www.opera.com/help/tutorials/security/cookies/
        </a>
      </p>
    </ol>
    <p>
      You can also delete the cookies stored in your browser by going to its
      configuration options.
    </p>
  </Container>
);

export default Legal;
