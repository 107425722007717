import { Button, Col, Container, Row, Stack } from "react-bootstrap";

import { faBomb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import NavLink from "../../components/shared/NavLink";
import classNames from "classnames";



const defaultMessage = "¡Ups! Parece que algo ha ido mal. Reintentalo en unos minutos."
const ErrorPage = (props) => {
  const { children = defaultMessage, icon = faBomb, navTo = window.location.href, className, ...stackProps } = props
  return (
    <Stack {...stackProps} className={classNames("error-page", className)}>
      <Container className="py-5">
        <Row className="flex-column-center mt-5" style={{ height: "50vh" }}>
          <Col xs="auto">
            <FontAwesomeIcon className="h1 text-danger mb-3" icon={icon} />
          </Col>
          <Col xs={12} md={6} className="h5 mb-5 text-center">
            {children}
          </Col>
          <Col xs="auto" className="h5">
            <NavLink to={navTo}>
              <Button variant="outline-danger">
                Reintentar
              </Button>
            </NavLink>
          </Col>
        </Row>
      </Container>
    </Stack>
  );
}

export default ErrorPage;