import { useEffect, useState } from "react";

const useStatus = (params) => {
  const { initialLoading = false } = params || {}
  const [isLoading, setIsLoading] = useState(initialLoading)
  const [error, setError] = useState()

  useEffect(() => {
    if (isLoading) setError(undefined)
  }, [isLoading])

  const load = () => {
    setIsLoading(true)
    setError(undefined)
  }

  const err = (error) => {
    console.log(error)
    setIsLoading(false)
    setError(error)
  }

  const succeed = () => {
    setIsLoading(false)
    setError(undefined)
  }

  return {
    isLoading,
    error,
    load,
    err,
    succeed
  }
}

export default useStatus;