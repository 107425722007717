
import { useEffect, useState } from "react";

import { useStrapi4QueryParams } from "@libraries/strapi-4-utils";

import useStatus from "./useStatus";
import { newsRequests } from "../api/Api";

const useNews = (options) => {
  const [news, setNews] = useState([]);
  const { isLoading, error, load, err, succeed } = useStatus({ initialLoading: true })
  const strapi4QueryParams = useStrapi4QueryParams(options);
  const { queryParams,pagination } = strapi4QueryParams;

  useEffect(() => { refresh()}, [queryParams]);

  const refresh = async () => {
    load();
    try {
      const [news] = await newsRequests.get(queryParams);
      setNews(news);
      succeed();
    } catch (e) {
      err(e?.message || "¡Ups! No se han podido cargar las noticias")
    }
  };


  return {
    ...strapi4QueryParams,
    news,
    isLoading,
    error
  }
}

export default useNews
