import RestApi from "@libraries/api";
import { parseStrapi4Response } from "@libraries/strapi-4-utils";
import configuration from "../configuration/config";

const {apiUrl} = configuration;

const Api = new RestApi(apiUrl, {
    processResponse: ({ data }) => parseStrapi4Response(data)});

 const defaultNewsPopulate = ["category"]
 const defaultCategoriesPopulate = ["news"]
const getNews = (params) => Api.get("/news",{params:{ sort: {publishedAt:'desc'},...params},populate:defaultNewsPopulate});

const newsRequests = {
    get: getNews
}
const getCategories = (params) => Api.get("/categories",{params:{...params,populate:defaultCategoriesPopulate}});

const categoriesRequests = {
    get: getCategories
}

export default Api;
export {
    newsRequests,
    categoriesRequests
}