import { useContext, useEffect } from "react";
import {  Row } from "react-bootstrap";

import { ModalContext } from "../../wrappers/ModalWrapper";
import NavLink from "./NavLink";



const NAME_VAR_STORAGE = "takeprofittoday-cookies-accept";
export const ModalCookies = () => {
    const modal = useContext(ModalContext);

    const accepted = localStorage.getItem(NAME_VAR_STORAGE);

    useEffect(() => {
        if (!accepted) {
            modal.setContent(<ModalCookiesContent onClick={handleSave} />);
        }
        // eslint-disable-next-line
    }, [accepted]);

    function handleSave() {
        localStorage.setItem(NAME_VAR_STORAGE, true);
        modal.setContent(null);
    }

    return null;
};


const ModalCookiesContent = ({ onClick }) => {
    return (
        <div className="container rounded shadow bg-white text-dark pt-2" style={{ width: '98%', maxWidth: 900, marginTop: '3vh' }}>
            <Row className="py-4 px-4 px-md-5" style={{ fontSize: "20px" }}>
            COOKIE NOTICE
            </Row>
            <Row className="px-4 px-md-5">
                <p>
                The website www.takeprofit.today uses its own and third-party cookies to collect information that helps optimize your visit to our web page. Cookies will not be used to collect personal information. You can allow their use or reject it; you can also change your settings at any time.                </p>
                <p>
                You can find more information in our  <NavLink  to="/cookies" className="text-primary" style={{ textDecoration: "underline" }}>Cookie Policy</NavLink>.
                </p>
            </Row>
            <Row className="justify-content-end pb-4 px-4 px-md-5">
                <button
                    onClick={onClick}
                    style={{ maxWidth: "200px" }}
                    className="btn text-white btn-block bg-primary rounded py-3"
                >Accept</button>
            </Row>
        </div>
    )

}
export default ModalCookies;