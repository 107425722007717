import { Col, Container, Row } from "react-bootstrap"
import NavLink from "../shared/NavLink";


const Footer = ({ categories }) => {
    return (
        <Container fluid className="mt-5 bg-dark p-3">
            <Container className="py-5 footer text-light mx-auto w-auto d-flex justify-content-center">
                <Row className="justify-content-evenly  mx-auto p-0 gy-5">
                    <Col xs={12} md={4} className="">
                        <Row className="p-0">
                            <h2>CATEGORIES</h2>
                            {categories.map((category, index) => (
                                <Col xs={12} md={12} key={index} className="">
                                    <NavLink key={index} className="footer-categories" to={`category/${category.identifier}`} style={{ textOverflow: 'ellipsis', lineHeight: '1.2em' }}>
                                        {category.name}
                                    </NavLink></Col>
                            ))}
                        </Row>
                    </Col>
                    <Col xs={12} md={4} className="w-auto">
                        <Row className="p-0 justify-content-start align-items-start h-auto ">
                            <h2>LEGAL</h2>
                            <Col md={12}>
                                <NavLink className="footer-categories" to="/cookies">Cookie Policy</NavLink>
                            </Col>
                            <Col />
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Footer;