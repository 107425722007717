import { Container } from "react-bootstrap";
import { useState } from "react";

import { motion } from "framer-motion";

import NavLink from "../../shared/NavLink";

const GalleryArticle = ({  image_url, title,isPrincipalBanner,id }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <NavLink to={`news/${id}/${title}`} className="text-decoration-none text-light position-relative">
            <Container className="d-flex flex-column w-100 h-100 category-link m-0 p-0" onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>
                <img src={image_url} alt="" className="w-100 h-100 position-absolute m-0 p-0" style={{ objectFit: 'cover' }} />
                <div style={{overflow: 'hidden'}} className="h-100">
                <motion.h6
                    className="overflow-hidden  p-2 d-none d-lg-flex"
                    style={{ lineHeight: '1.5em', textOverflow: 'ellipsis' }}
                    initial={{ y: 650 }}
                    animate={{ y: isHovered ? (isPrincipalBanner? 360 :200) : 650 }}
                    transition={{ duration: 0.8 }}
                >
                    {title}
                </motion.h6>
                </div>
                <span className="d-lg-none py-2 banner-title" style={{zIndex:1}}>{title}</span>
            </Container>
        </NavLink>
    );
}

export default GalleryArticle;
