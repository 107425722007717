import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import useNews from "../../hooks/useNews";

import ErrorPage from "./ErrorPage";
import Loading from "./Loading";
import Home from "./Home";
import NavLink from "../../components/shared/NavLink";

const News = () => {
    const { id } = useParams();

    const { news, error, isLoading } = useNews({
        filters: { "filters[id]": id },
        depsToReset: [id],
    });
    const { url, title, authors, time_published, image_url, description } = news?.[0] || {};

    const { news: similarNews, error: errorNews, isLoading: newsLoading } = useNews({
        filters: { "filters[id][$ne]": id },
        pagination: { pageSize: 8 },
        depsToReset: [id],
    });

    if (isLoading || newsLoading) return <Loading />;
    if (!!error || !!errorNews) return <ErrorPage children={error} />;
    if (news.length === 0) return <Home />;

    return (
        <Container className="mb-5 news-details mt-5">
            <Row className="g-5 w-auto ">
                <Col md={8}>
                    <h2 className="news-title"><NavLink to={url}><h4>{title}</h4></NavLink></h2>
                    <div className="text-muted my-3">
                        {authors && <div>By {authors} </div>}
                        {time_published && <div>Published {time_published}  </div>}
                    </div>
                    <div style={{ overflow: 'hidden' }}>
                        <img src={image_url}  style={{ objectFit: 'cover' }} alt="news images" />
                    </div>
                    <hr className="mt-4" />
                    <div className="h-auto description" dangerouslySetInnerHTML={{__html: description.replaceAll("\n", "<br/>")}}/>
                    {url && <NavLink to={url} className="text-dark">read more</NavLink>}
                </Col>
                <Col md={4} >
                    <hr className="separator-similar-news"/>
                    <h2 className="mt-2 fw-bold py-2 px-0">LATEST NEWS</h2>
                    <Row className="flex-column gy-3 latest-news w-100 px-0 mx-auto">
                        {similarNews.map((simNew, index) => (
                            <Col md={10} key={index} className="d-flex w-100">
                                <NavLink to={`/news/${simNew.id}/${simNew?.title}`}>
                                    <h5>{simNew?.title}</h5>
                                </NavLink>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};
export default News;
