import { Container, Stack } from "react-bootstrap";
import { useMemo } from "react";

import useNews from "../../hooks/useNews";
import useCategories from "../../hooks/useCategories";

import GalleryBanner from "../../components/banner/galleryBanners/GalleryBanner";
import BannerGrid from "../../components/banner/BannerGrid";
import NavLink from "../../components/shared/NavLink";
import ErrorPage from "./ErrorPage";
import Loading from "./Loading";

const Home = () => {

  const { categories, isLoading } = useCategories();
  const { news, error, isLoading: newsLoading } = useNews();

  const principalNews = useMemo(() => {
    const copyNews = news.filter((item, index, self) => index === self.findIndex((t) => t.image_url === item.image_url)).slice(0, 5);
    [copyNews[0], copyNews[1]] = [copyNews[1], copyNews[0]];
    return copyNews;
  }, [news]);

  const newsByCategory = (category) => {
    const principalNewsIds = principalNews.map((n) => n.id);
    return category.news
      .filter((n) => !principalNewsIds.includes(n.id))
      .filter((item, index, self) => index === self.findIndex((t) => t.image_url === item.image_url))
      .slice(0, 4);
  };

  const currentError = error;
  const somethingIsLoading = isLoading || newsLoading;
  if (somethingIsLoading) return <Loading />;
  if (!!currentError) return <ErrorPage children={currentError} />;

  return (
    <Stack>
      <GalleryBanner banners={principalNews} />
      {categories &&
        categories.map((c, index) => (
          <Container className="my-3 " key={index}>
            <div className="d-flex flex-row justify-content-between align-items-center category-name">
              <h1 className="fw-bold">{c.name} </h1>
              <NavLink
                to={`category/${c.identifier}`}
                className="text-dark"
              >
                View all
              </NavLink>
            </div>
            <BannerGrid banners={newsByCategory(c)} />
          </Container>
        ))}
    </Stack>
  );
};

export default Home;
