import { Col, Row } from "react-bootstrap";

import classNames from "classnames";
import GalleryArticle from "./GalleryArticle";

const GalleryBanner = ({ banners, className }) => {
    return (
        <div className={classNames("banner-grid   px-3 pb-4 mb-lg-5", className)}>
             <h1 className="mt-3 fw-bold w-50">Latest</h1>
            <Row className="g-sm-2 justify-content-center grid-category-area">
                {banners.map((banner, i) =>
                    <Col key={i} style={{gridArea:(i === 1 ) ? "first-category-banner" : `others-category-banner-`+i}} className={(i === 1 ) ?"first-banner-category" : `others-banner-category others-category-banner-${i}` }>
                        <GalleryArticle  isPrincipalBanner={i === 1 ? true: false} {...banner} key={i}  />
                    </Col>
                )} 
            </Row>
        </div>
    );
}
export default GalleryBanner;