import { Button, Col, Row } from "react-bootstrap";

import NavLink from "../shared/NavLink";

import classNames from "classnames";

const Banner = ({ url, image_url, title, time_published, readMore, className, isPrincipalBanner, showTime, authors,allWidth,id }) => {
    return (
        <div className="d-flex flex-column justify-content-between h-100" >
            <div>
                <NavLink to={`/news/${id}/${title}`} className={classNames("banner-link h-100", className)}  >
                    <Col xs={12} md={4} className={allWidth ? "w-100" : ""}>
                    <img src={image_url}  style={{ objectFit: 'cover'}} alt="banner"/>
                    </Col>
                    <Col>
                    <h6 className="overflow-hidden" style={{ lineHeight: '1.5em', textOverflow: 'ellipsis', maxHeight: isPrincipalBanner ? 'auto' : '100px', overflow: 'hidden', marginTop: '10px' }}>{title}</h6>
                    </Col>
                </NavLink>
            </div>
            {isPrincipalBanner &&
                <div className="d-flex flex-column h-100 justify-content-between mt-3">
                    <Row className="text-muted fw-bold mt-2 flex-row justify-content-between align-items-center w-100">{authors && <Col className="text-gray">By {authors}</Col>} <Col className="text-dark fw-0  text-end">{time_published}</Col></Row>
                </div>}
            {showTime && <div>{time_published}</div>}
            {readMore && <NavLink to={url} className="mx-auto w-100 text-center"><Button className="mt-25 text-light w-100" variant="primary" style={{ justifySelf: 'flex-end', alignContent: 'flex-end' }} >Read more</Button></NavLink>}
        </div>
    );
}

export default Banner;