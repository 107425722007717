import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { useRef } from "react";
import Slider from "react-slick";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import NavLink from "../shared/NavLink";
import classNames from "classnames";

import logo3 from "../../media/logo3.svg";
import logo4 from "../../media/logo4.svg";

const Navbar = ({ categories }) => {
    // eslint-disable-next-line
    let sliderRef = useRef(null);
    const heightNav = '100px';

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <div {...props}
            className={"slick-prev slick-arrow"}
            type="button">
            <IoIosArrowBack className=" prev-slick-arrow" />
        </div>
    );
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className={"slick-next slick-arrow" }
            type="button">
            <IoIosArrowForward className="next-slick-arrow" />
        </div>
    );

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        arrows: true,
        accessibility: true,
        centerPadding: '0px',
        touchMove: false,
        swipeToSlide: true,
        className: 'slider-bar',
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
    };

    return (
        <Container fluid className="w-100" style={{ height: 'auto' }}>
            <SupNav widthNav="350px" className="p-3 position-fixed w-100 top-0 d-none d-lg-flex" height={heightNav} logo={logo4} position="text-start" />

            <SupNav widthNav="100px" className="position-fixed w-100 top-0 d-lg-none py-2 text-light" height={'auto'} logo={logo3} position="text-center mx-auto" categories={categories} />

            {categories && (
                <Col md={6} className="mb-2 links mx-auto d-none d-lg-flex" style={{ marginTop: heightNav }}>
                    <Slider ref={slider => (sliderRef = slider)} {...settings} className="w-100 p-2">
                        {categories.map((c, index) => (
                            <NavLink key={index} className="categories-bar py-2" to={`category/${c.identifier}`} style={{ textOverflow: 'ellipsis' }}>
                                {c.name}
                            </NavLink>
                        ))}
                    </Slider>
                </Col>
            )}
        </Container>
    );
}

const SupNav = ({ widthNav, className, logo, position, categories }) => {
    return (
        <Row className={classNames(className, "nav align-items-center")} style={{ zIndex: 4 }}>
            {categories && (
                <ColDropdownLinks categories={categories} className="d-lg-none">
                    <FontAwesomeIcon icon={faBars} />
                </ColDropdownLinks>
            )}
            <Col md={12} xs={4} className={classNames("w-auto",position)}>
                <NavLink to={"/"}> <img src={logo} width={widthNav} alt="logo" /></NavLink>
            </Col>
            <Col xs={4} />
        </Row>
    );
}

const ColDropdownLinks = ({ children, categories }) => {
    return (
        <Col xs={4} className="p-0 position-relative no-arrow">
            <Dropdown className="no-arrow size-100">
                <Dropdown.Toggle as={"div"} className="px-4 py-3 size-100">
                    {children}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {categories.map((c, index) => (
                        <NavLink to={`category/${c.identifier}`} key={index}>
                            <Dropdown.Item as="div">
                                {c.name}
                            </Dropdown.Item>
                        </NavLink>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </Col>
    );
}

export default Navbar;
