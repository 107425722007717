import { useEffect, useState } from "react";
import { categoriesRequests } from "../api/Api";

import { useStrapi4QueryParams } from "@libraries/strapi-4-utils";

import useStatus from "./useStatus";

const useCategories = (options) => {

  const [categories, setCategories] = useState();
  const strapi4QueryParams = useStrapi4QueryParams(options);
  const { isLoading, error, load, err, succeed } = useStatus({ initialLoading: true })
  const { queryParams } = strapi4QueryParams

  useEffect(() => { refresh() }, [queryParams]);

  const refresh = async () => {
    load();
    try {
      const [categories] = await categoriesRequests.get(queryParams);
      setCategories(categories.sort((a, b) => a.name.localeCompare(b.name)));
      succeed();
    } catch (e) {
      err(e?.message || "¡Ups! No se han podido cargar las categorías");
    }
  }
  return {
    categories,
    ...strapi4QueryParams,
    isLoading,
    error
  }

}
export default useCategories;

