import React from "react";
import { NavLink as BaseNavLink } from "react-router-dom";


const NavLink = ({ children, to, ...navLinkProps }) => {
  if (!to) return <React.Fragment>{children}</React.Fragment>;
  if (to.includes("http")) return <AnchorLink children={children} to={to} {...navLinkProps}/>;
  return <BaseNavLink to={to} children={children} {...navLinkProps} />;
}

const AnchorLink = ({ children, to, className, style, ...navLinkProps }) => {
  const isCurrentUrl = window.location.href === to

  const rcn = typeof className === "function" ? className({ isActive: isCurrentUrl, isPending: false, isTransitioning: false }) : className
  const rs = typeof style === "function" ? style({ isActive: isCurrentUrl, isPending: false, isTransitioning: false }) : style
  return <a href={to} children={children}  className={rcn} style={rs} {...navLinkProps}/>;
}

export default NavLink;